body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #444444;
  text-decoration: none;
}

a:hover {
  color: #777777;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}


section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f6f7f6;
}

.section-title {
  padding-bottom: 40px;
}
.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}
.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #9ae1af;
  margin: 4px 10px;
}
.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #37423b;
}

.breadcrumbs {
  margin-top: 73px;
  text-align: center;
  background: #5fcf80;
  padding: 30px 0;
  color: #fff;
}
@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 63px;
  }
}
.breadcrumbs h2 {
  font-size: 32px;
  font-weight: 500;
}
.breadcrumbs p {
  font-size: 14px;
  margin-bottom: 0;
}