.startups .startup-item {
  border-radius: 5px;
  border: 1px solid #eef0ef;
}
.startups .startup-content {
  padding: 15px;
}
.startups .startup-content h3 {
  font-weight: 700;
  font-size: 20px;
}
.startups .startup-content h3 a {
  color: #37423b;
  transition: 0.3s;
}
.startups .startup-content h3 a:hover {
  color: #5fcf80;
}
.startups .startup-content p {
  font-size: 14px;
  color: #777777;
}
.startups .startup-content h4 {
  font-size: 14px;
  background: #5fcf80;
  padding: 7px 14px;
  color: #fff;
  margin: 0;
}
.startups .startup-content .price {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  color: #37423b;
}
.startups .project {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #eef0ef;
}
.startups .project:last-child{
  padding-bottom: 0px
}
.startups .project .project-profile img {
  max-width: 40px;
  border-radius: 40px;
}
.startups .project .project-profile span {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #5a6c60;
}
.startups .project .project-rank {
  font-size: 18px;
  color: #657a6d;
}